import React from "react"
import { graphql } from "gatsby"

import DocsLayout from "../components/Docs/DocsLayout"
// import PostLinks from "../components/Blog/PostLinks"

import ReactMarkdown from "react-markdown"
import SEO from "../components/SEO"

const HelpDoc = ({ data, pageContext, path }) => {
  // const previous = pageContext.prev
  // const next = pageContext.next
  // const { basePath } = pageContext
  // var toc = require('markdown-toc');
  const {
    title,
    seoMetaTitle,
    seoMetaDescription,
    body: { body },
  } = data.doc

  const seoTitle = seoMetaTitle ? seoMetaTitle : title
  const seoDescription = seoMetaDescription ? seoMetaDescription : null

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname={path}
      />
      <DocsLayout path={path}>
        <article className="help-article">
          <header className="help-article__header">
            <h1 className="help-article__header-title">{title}</h1>
            {/* <h4>{category.title}</h4> */}
          </header>
          <section className="help-article__content">
            <ReactMarkdown source={body} />
          </section>
          {/* <PostLinks previous={previous} next={next} basePath={basePath} /> */}
        </article>
      </DocsLayout>
    </>
  )
}

export const getHelpDoc = graphql`
  query getHelpDoc($id: String!) {
    doc: contentfulHelpDoc(contentful_id: { eq: $id }) {
      title
      seoMetaTitle
      seoMetaDescription
      category {
        title
        slug
      }
      body {
        body
      }
    }
  }
`

export default HelpDoc
